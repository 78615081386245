import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LendIcon from "../assets/svgs/icon-become-lender.svg";
import SavingsIcon from "../assets/svgs/icon-saving.svg";
import LowVolLoan from "../assets/svgs/icon-low-vol-loan.svg";
import CryptoLoan from "../assets/svgs/icon-crypto-loans.svg";
import { useFetchGlobalVigorStats } from "../utils/hooks";
import { media } from "../utils/breakpoints";
import { ColorVariantProps, variant2Color } from "./shared/color-props";
import { formatPercentage } from "../utils/format";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.lessThan(`xs-max`)} {
    flex-direction: column;
  }
`;

const Box = styled.div<ColorVariantProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 48px;
  color: ${variant2Color};

  background-color: ${(props) => props.theme.colors.bgLight};
  & > h2 {
    font-size: 22px;
    text-transform: uppercase;
    color: currentColor;
    margin: 0 0 16px 0;
  }
  & > p {
    font-weight: 500;
    text-align: center;
    color: ${(props) => props.theme.colors.white};
  }

  & > a {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.bg};
    padding: 11px 50px;
    text-decoration: none;
    border-radius: 4px;
    background-color: ${variant2Color};
  }

  ${media.lessThan(`xs-max`)} {
    padding: 48px 16px;
  }
`;

const InfoGraphicsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 32px 0;
`;
const InfoGraphic = styled.div`
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 4px;
  padding: 34px 0;
  &:first-child {
    margin: 0 32px 0 0;
  }

  & > h3 {
    color: currentColor;
    text-transform: uppercase;
    text-align: center;
  }
  & > span {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.white};
  }
  & > span:last-of-type {
    font-size: 22px;
    font-weight: 500;
  }
  & > svg {
    height: 60px;
  }

  ${media.lessThan(`xs-max`)} {
    &:first-child {
      margin: 0 16px 0 0;
    }
  }
`;

const BorrowEarnTabs: React.FC = (props) => {
  const { t } = useTranslation();
  const vigorData = useFetchGlobalVigorStats();

  return (
    <Wrapper>
      <Box colorVariant="secondary">
        <h2>{t(`appRates.rewards`)}</h2>
        <p>{t(`appRates.rewardsDesc`)}</p>
        <InfoGraphicsWrapper>
          <InfoGraphic>
            <LendIcon />
            <h3>{t(`insure`)}</h3>
            <span>
              {vigorData.global
                ? `${formatPercentage(
                    Number.parseFloat(vigorData.global.earnrate)
                  )}%`
                : ``}
            </span>
          </InfoGraphic>
          <InfoGraphic>
            <SavingsIcon />
            <h3>{t(`appRates.savings`)}</h3>
            <span>
              {vigorData.global
                ? `${formatPercentage(
                    Number.parseFloat(vigorData.global.savingsrate)
                  )}%`
                : ``}
            </span>
          </InfoGraphic>
        </InfoGraphicsWrapper>

        <a
          href="https://app.vigor.ai/rewards"
          title={t(`insure`)}
          rel="noopener noreferrer"
        >
          {t(`appRates.rewardsButton`)}
        </a>
      </Box>

      <Box colorVariant="primary">
        <h2>{t(`appRates.borrow`)}</h2>
        <p>{t(`appRates.borrowDesc`)}</p>
        <InfoGraphicsWrapper>
          <InfoGraphic>
            <LowVolLoan />
            <h3>{t(`appRates.lowVol`)}</h3>
            <span>{t(`appRates.ratesLow`)}</span>
            <span>
              {vigorData.config
                ? formatPercentage(vigorData.config.mintesprice / 10000)
                : `2`}
              %
            </span>
          </InfoGraphic>
          <InfoGraphic>
            <CryptoLoan />
            <h3>{t(`appRates.cryptoLoan`)}</h3>
            <span>{t(`appRates.ratesLow`)}</span>
            <span>
              {vigorData.config
                ? formatPercentage(vigorData.config.mintesprice / 10000)
                : `2`}
              %
            </span>
          </InfoGraphic>
        </InfoGraphicsWrapper>

        <a
          href="https://app.vigor.ai/borrow"
          title={t(`appRates.borrow`)}
          rel="noopener noreferrer"
        >
          {t(`appRates.borrowButton`)}
        </a>
      </Box>
    </Wrapper>
  );
};

export default BorrowEarnTabs;
