import React from 'react';
import styled, { css } from 'styled-components';

type WrapperProps = {
  center?: boolean;
}

const JumbotronWrapperCenterVariant = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const JumbotronWrapper = styled.div<WrapperProps>`
  padding: 0 16px;
  margin-top:152px;
  ${(props) => props.center ? JumbotronWrapperCenterVariant : ''};
`;

const JumbotronTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 28px;
  max-width: 526px;
  color: ${props => props.theme.colors.primary};
  letter-spacing: 0.75px;
  line-height: 44px;
`;

const JumbotronSubtitle = styled.div`
  font-size: 16px;
  max-width: 434px;
  color: ${props => props.theme.colors.whiteDarkened};
  letter-spacing: 0.3px;
  line-height: 24px;
  font-weight: 500;
`;

const JumbotronLinkWrap = styled.div`
  
  a {
    padding: 11px 0;
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height:19px;
    text-transform: uppercase;
  }
`;

type Props = {
  title: string,
  subtitle?: string,
  link?: React.ReactNode,
  center?: boolean,
};

const Jumbotron: React.FC<Props> = props => {
  const { title, subtitle, link, center } = props;

  return (
    <JumbotronWrapper center={center}>
      <JumbotronTitle>{ title }</JumbotronTitle>
      { subtitle ? <JumbotronSubtitle>{ subtitle }</JumbotronSubtitle> : false }
      { link ? <JumbotronLinkWrap>{ link }</JumbotronLinkWrap> : false }
    </JumbotronWrapper>
  );
};

export default Jumbotron;
