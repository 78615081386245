import React from "react";
import styled from "styled-components";

import { media } from "../utils/breakpoints";

const JumbotronWrapper = styled.div`
  margin-top: 100px;

  &primary--color span,
  &.primary--color h3 {
    color: ${(props) => props.theme.colors.primary};
  }

  &secondary--color span,
  &.secondary--color h3 {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const JumbotronSubtitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  max-width: 516px;
  letter-spacing: 0.75px;
  line-height: 29px;

  ${media.lessThan("xs-max")} {
    margin: 1rem;
  }
`;

const JumbotronSubtitle2 = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.colors.secondary};
  font-weight: bold;
  max-width: 516px;
  letter-spacing: 0.75px;
  line-height: 29px;
`;

const JumbotronContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

// desktop: two rows for 4 items, 1 row for 3 or less items
const JumbotronRow = styled.div<any>`
  flex-basis: ${props => props.size === 4 ? `45.4%` : `${95 / props.size}%`};
  margin: 1rem auto;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.3px;
  }

  ${media.lessThan("md")} {
    flex-wrap: wrap;
    flex-direction: column;
    flex-basis: 44.5%;
    margin: 1rem auto;

    p {
      width: 288px;
    }
  }

  ${media.lessThan("xs-max")} {
    flex-basis: 88.5%;
  }
`;

const Logo: React.FC<{ logo: any; height?: number }> = ({ logo, height }) => {
  if (typeof logo === `string`) {
    return <img height={height || 72} src={logo} />;
  }

  const Element = logo;
  return <Element height={height || 72} />;
};

type Props = {
  title: string;
  cards: [];
  theme: string;
};

const JumbotronWithCard: React.FC<Props> = (props) => {
  const { cards, title, theme } = props;

  const cardItems = cards.map((card: any) => (
    <JumbotronRow key={card.title} size={cards.length}>
      <Logo logo={card.logo} />
      <JumbotronSubtitle2>{card.title}</JumbotronSubtitle2>
      <p>{card.description}</p>
    </JumbotronRow>
  ));

  return (
    <div>
      <JumbotronWrapper className={theme}>
        <JumbotronSubtitle>{title}</JumbotronSubtitle>

        {cards && cards.length > 0 && (
          <JumbotronContentWrapper>{cardItems}</JumbotronContentWrapper>
        )}
      </JumbotronWrapper>
    </div>
  );
};

export default JumbotronWithCard;
