import React from 'react';
import { GridCentered } from "./shared";
import styled from "styled-components";


import BackgroundComparisonBox from "../assets/images/backgrounds/vigor-pattern-seamless.png";

export const ComparisonBoxWrapper = styled(GridCentered)`

  background: url(${props => props.imageUrl});
`;
