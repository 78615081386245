import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import EosWriter from "../assets/svgs/partner/eoswriter.svg";
import EosDAC from "../assets/svgs/partner/eosdac.svg";
import Moran from "../assets/images/partner/moran.png";
import NovaWallet from "../assets/images/partner/novawallet.png";
import TokenPocket from "../assets/images/partner/tokenpocket.png";
import MathWallet from "../assets/images/partner/MathWallet.png";
import Lynx from "../assets/images/partner/lynx.png";
import LiquidApps from "../assets/svgs/partner/liquidapps_logo.svg";
import MeetOne from "../assets/images/partner/meetone.png";
import StartEOS from "../assets/images/partner/StartEOS.png";
import Layer from "../assets/svgs/partner/layer.svg";
import Dice from "../assets/images/partner/dice.png";
import Lumi from "../assets/svgs/partner/Lumi.svg";
import UpliftNation from "../assets/svgs/partner/uplift-nation.svg";
import Scatter from "../assets/svgs/partner/scatter-logo.svg";
import EverythingEOS from "../assets/images/partner/everything-eos.png";
import cronEOS from "../assets/svgs/partner/croneos.svg";
import { media } from "../utils/breakpoints";

const PartnersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 62px;
`;

const Headline = styled.h2`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 56px;
  color: ${p => p.theme.colors.primary};
  text-align: center;
  margin: 0 0 40px 0;
`;

const LogosWrapper = styled.div`
  display: grid;
  
  justify-items: center;
  align-items: center;
  width: 100%;
  max-width: ${p => p.theme.breakpoints.md}px;

  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 48px 0;

  /* & > *:last-child {
    grid-column: 1 / span 4;
  } */

  ${media.lessThan(`md`)} {
    grid-template-columns: 50% 50%;
    grid-gap: 36px 0;

    /* & > *:last-child {
      grid-column: 1 / span 2;
    } */
  }
`;

type Props = {};

const Logo: React.FC<{ logo: any; title: string, height?: number }> = ({ logo, title, height }) => {
  if (typeof logo === `string`) {
    return <img height={height || 55} src={logo} alt={title} title={title} />;
  }

  const Element = logo;
  return <Element height={height || 55} />;
};

const Partners: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <PartnersWrapper>
      <Headline>{t(`partners`)}</Headline>
      <LogosWrapper>
        <Logo logo={EosWriter} title='EosWriter'/>
        <Logo logo={EosDAC} title='EosDAC'/>
        <Logo logo={Moran} height={40} title='Moran'/>
        <Logo logo={NovaWallet} height={30} title='NovaWallet'/>
        <Logo logo={TokenPocket} height={25} title='TokenPocket'/>
        <Logo logo={MathWallet} height={20} title='MathWallet'/>
        <Logo logo={Lynx} height={35} title='Lynx'/>
        <Logo logo={LiquidApps} height={30} title='LiquidApps'/>
        <Logo logo={MeetOne} title='MeetOne'/>
        <Logo logo={StartEOS} title='StartEOS'/>
        <Logo logo={Layer} title='Layer'/>
        <Logo logo={Dice} title='Dice'/>
        <Logo logo={Lumi} height={35} title='Lumi'/>
        <Logo logo={UpliftNation} height={55} title='UpliftNation'/>
        <Logo logo={Scatter} height={40} title='Scatter'/>
        <Logo logo={EverythingEOS} title='EverythingEOS'/>
        <Logo logo={cronEOS} height={28} title='CronEOS'/>
      </LogosWrapper>
    </PartnersWrapper>
  );
};

export default Partners;
