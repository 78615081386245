import React from "react";
import { useTranslation, Trans } from "react-i18next";
import JumbotronWithCard from "./JumbotronWithCard";

import Earn from "../assets/svgs/earn-vig.svg";
import FinalReserve from "../assets/svgs/finalreserve.svg";
import CreditScore from "../assets/svgs/creditscore.svg";
import Governance from '../assets/svgs/hedge_copy.svg';

const Utility: React.FC = (props) => {
  const { t } = useTranslation();

  const title = (
    <Trans i18nKey="utility.title">
      What is the utility of the <span>VIG</span> token?
    </Trans>
  );

  const theme = "secondary--color";

  const cards = [
    {
      title: t(`utility.earn`),
      logo: Earn,
      description: t(`utility.earnDescription`),
    },
    {
      title: t(`utility.finalReserve`),
      logo: FinalReserve,
      description: t(`utility.finalReserveDescription`),
    },
    {
      title: t(`utility.creditScore`),
      logo: CreditScore,
      description: t(`utility.creditScoreDescription`),
    },
    {
      title: t(`utility.governance`),
      logo: Governance,
      description: t(`utility.governanceDescription`),
    },
  ];

  const config = { theme, cards, title };

  return <JumbotronWithCard {...config} />;
};

export default Utility;
