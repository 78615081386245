import React from "react";
import { useTranslation } from "react-i18next";
import Hero from "../components/Hero";
import BorrowEarnTabs from "../components/BorrowEarnTabs";
import Jumbotron2 from "../components/Jumbotron2";
import Utility from "../components/Utility";
import LocalizedLink from "../components/LocalizedLink";
import CompareDeFiTable from "../components/CompareDeFiTable";
import Grid from "styled-components-grid";
import Partners from "../components/Partners";
import Jumbotron from "../components/Jumbotron";
import WhatCanYouDo from "../components/WhatCanYouDo";
import GetInvolved from "../components/GetInvolved";
import { GridCentered } from "../components/shared";
import { ComparisonBoxWrapper } from "../components/ComparisonBoxWrapper";
import styled from "styled-components";


import BackgroundComparisonBox from "../assets/images/backgrounds/vigor-pattern-seamless.png";

import backgroundVigorProtocolBox from "../assets/images/backgrounds/vigor-pattern.png";

const VigorProtocolBoxWrapper = styled(GridCentered)`
  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-position: 160% top;
`;


const UtilityBoxWrapper = styled(GridCentered)`
  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-position: 160% top;
`;


const DecentralizedBoxWrapper = styled(GridCentered)`
  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-position: -60% top;
`;


const Index: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <VigorProtocolBoxWrapper imageUrl={backgroundVigorProtocolBox}>
        <Grid.Unit size={10 / 12}>
          <Hero
            title={t(`vigorStablecoin`)}
            subtitle={
              <React.Fragment>
                {t(`getInvolvedHero-title-row1`)}
                <br />
                {t(`getInvolvedHero-title-row2`)}
              </React.Fragment>
            }
            link={
              <LocalizedLink to={"/community"}>
                {t(`getInvolved`)}
              </LocalizedLink>
            }
          />
          <BorrowEarnTabs />
        </Grid.Unit>
      </VigorProtocolBoxWrapper>

      <DecentralizedBoxWrapper imageUrl={backgroundVigorProtocolBox}>
        <Grid.Unit size={10 / 12}>
          <Jumbotron2
            title={t(`joinBuildEarn-title`)}
            content={t(`joinBuildEarn-content`)}
            link={
              <LocalizedLink to={"/community"}>
                {t(`getInvolved`)}
              </LocalizedLink>
            }
          />
        </Grid.Unit>
      </DecentralizedBoxWrapper>

      <GridCentered>
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <Jumbotron
            title={t(`borrowLendInsure-subtitle`)}
            link={
              <a href={"../vigor.pdf"} target="_blank">
                {t(`readTheWhitepaper`)} <strong>&#8594;</strong>
              </a>
            }
          />
        </Grid.Unit>
      </GridCentered>

      <UtilityBoxWrapper imageUrl={backgroundVigorProtocolBox}>
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <Utility />
        </Grid.Unit>
      </UtilityBoxWrapper>

      <GridCentered>
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <WhatCanYouDo />
        </Grid.Unit>
      </GridCentered>

      <ComparisonBoxWrapper id="defi-comparison" imageUrl={BackgroundComparisonBox}>
        <Grid.Unit size={{ sm: 10 / 12 }} id="compareDefi">
          <CompareDeFiTable />
        </Grid.Unit>
      </ComparisonBoxWrapper>

      <GridCentered>
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <GetInvolved />
        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Partners />
      </GridCentered>
    </React.Fragment>
  );
};

export default Index;
