import React from 'react';
import styled from "styled-components";
import { useTranslation, Trans } from 'react-i18next';
import JumbotronWithCard from './JumbotronWithCard';

import Borrow from '../assets/svgs/icon-crypto-loans.svg';
import Lend from '../assets/svgs/icon-become-lender.svg';
import Save from '../assets/svgs/icon-saving.svg';

const TitleStrong = styled.div`
  display: inline-block;
  color:  ${props => props.theme.colors.primaryLighter}
`;

const WhatCanYouDo: React.FC = props =>{

  const { t } = useTranslation();

  const title = ( <Trans i18nKey="whatCanYouDo.title"> What can you do with <TitleStrong>VIGOR</TitleStrong>?</Trans> );
  
  const theme = 'primary--color';
  
  const cards = [{
    title: t( `whatCanYouDo.borrow` ),
    logo: Borrow,
    description: t( `whatCanYouDo.borrowDescription` ),

  }, {
    title: t( `whatCanYouDo.lend` ),
    logo: Lend,
    description: t( `whatCanYouDo.lendDescription` ),
  },{
    title: t( `whatCanYouDo.hedge` ),
    logo: Save,
    description: t( `whatCanYouDo.hedgeDescription` ),
  }];
  
  const config = { theme, cards, title };


  return (
    <JumbotronWithCard {...config } />
  );
};

export default WhatCanYouDo;
